import React from 'react'
import './MovingGifs.css' // Le fichier CSS pour les animations

const MovingGifs = () => {
  return (
    <div>
      {/* GIF de vélo - Au-dessus du texte */}
      <div className="gif-container bike">
        <img
          style={{ width: 'inherit' }}
          src="/gifs/bike.gif"
          alt="Bicycle Animation"
        />
      </div>

      {/* GIF de rollers - En-dessous du texte */}
      <div className="gif-container rollers">
        <img
          style={{ width: 'inherit' }}
          src="/gifs/roller.gif"
          alt="Rollers Animation"
        />
      </div>
    </div>
  )
}

export default MovingGifs
