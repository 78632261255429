// src/App.tsx
import React from 'react'
import BackgroundVideo from './BackgroundVideo'
import PhotoGallery from 'PhotoGallery'

const App: React.FC = () => {
  return (
    <div>
      {/* <PhotoGallery /> */}
      <BackgroundVideo />
      {/* Autres éléments de la page */}
    </div>
  )
}

export default App
