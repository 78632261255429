import React, { useEffect, useState } from 'react'

const images = Array.from({ length: 39 }, (_, i) => `/media/image${i + 1}.jpg`)

const BackgroundCarousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length)
    }, 4000) // Change d'image toutes les 4 secondes
    return () => clearInterval(interval)
  }, [])

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: -1, // Pour que le carrousel soit derrière le contenu
      }}
    >
      {images.map((src, index) => (
        <img
          key={index}
          src={src}
          alt={`Background ${index + 1}`}
          className="birthday-carousel"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: currentImageIndex === index ? 0.7 : 0, // Transparence pour l'image active
            transition: 'opacity 2s ease-in-out', // Effet de fondu
          }}
        />
      ))}
    </div>
  )
}

export default BackgroundCarousel
