import React from 'react'
import Flickity, { FlickityOptions } from 'react-flickity-component'
import 'flickity/css/flickity.css'
import './PhotoGallery.css' // Style personnalisé pour ajuster l'affichage

const mediaItems = [
  ...Array.from({ length: 39 }, (_, index) => ({
    type: 'image',
    src: `/media/image${index + 1}.jpg`,
    thumb: '',
  })),
  {
    type: 'video',
    src: '/media/video1.mp4',
    thumb: '/media/video1-poster.jpg',
  },
  {
    type: 'video',
    src: '/media/video2.mp4',
    thumb: '/media/video2-poster.jpg',
  },
]

const flickityOptions: FlickityOptions = {
  cellAlign: 'center',
  contain: true,
  autoPlay: false,
  wrapAround: true,
  pageDots: false,
  adaptiveHeight: true,
  // setGallerySize: false,
}

type Props = {
  hidePhotos: () => void
}

const MediaGallery = (props: Props) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute' as const,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(to bottom right, rgba(0, 0, 255, 0.5), rgba(128, 0, 128, 0.5))',
          zIndex: -1,
        }}
      />
      <Flickity className="carousel" options={flickityOptions}>
        {mediaItems.map((item, index) => (
          <div
            key={index}
            className="carousel-cell"
            style={{
              position: 'relative',
              overflow: 'hidden',
              maxWidth: '80%',
              maxHeight: '80rem',
              margin: 'auto',
            }}
          >
            {item.type === 'image' ? (
              <img
                src={item.src}
                alt={`Media ${index + 1}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }}
              />
            ) : (
              <video
                src={item.src}
                controls
                poster={item.thumb}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }}
              />
            )}
          </div>
        ))}
      </Flickity>
      <button onClick={props.hidePhotos} style={styles.button}>
        Quitter
      </button>
    </div>
  )
}

const styles = {
  button: {
    position: 'absolute' as const,
    top: '20px',
    right: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
}

export default MediaGallery
