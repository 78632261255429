import React, { useEffect, useRef } from 'react'

const BackgroundMusic = () => {
  const audioRef = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    // Joue automatiquement la musique lorsque le composant est monté
    const audio = audioRef.current
    if (audio) {
      audio.currentTime = 3
      audio.volume = 1 // Ajustez le volume entre 0 et 1
      audio.play().catch(error => {
        console.error('Autoplay bloqué :', error)
      })
    }
  }, [])

  return <audio ref={audioRef} src="/media/kemar.mp3" loop />
}

export default BackgroundMusic
