// src/DinoGame.tsx
import React, { useEffect, useMemo, useRef, useState } from 'react'

import './DinoGame.css'
import Confetti from 'react-confetti'
import BackgroundCarousel from 'BackgroundCarousel'
import MovingGifs from 'MovingGifs'
import BackgroundMusic from 'BackgroundMusic'

type Props = {
  toggleGame: () => void
}

const DinoGame = (props: Props) => {
  const { toggleGame } = props

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [isGameRunning, setIsGameRunning] = useState(false)
  const [isWin, setIsWin] = useState(false)
  const animationIdRef = useRef<number | null>(null) // Référence pour annuler l'animation

  const handleStopGame = () => {
    setIsGameRunning(false)
    toggleGame()
  }

  const backgroundSprite = useMemo(() => {
    const img = new Image()
    img.src = '/sprites/background.png' // Remplacez par le chemin du sprite de Luffy
    return img
  }, [])

  const runSprite = useMemo(() => {
    const img = new Image()
    img.src = '/sprites/zoro-run.png' // Remplacez par le chemin du sprite de Luffy
    return img
  }, [])

  const ichigoSprite = useMemo(() => {
    const img = new Image()
    img.src = '/sprites/ichigo.png' // Remplacez par le chemin du sprite de l'obstacle
    return img
  }, [])

  const narutoSprite = useMemo(() => {
    const img = new Image()
    img.src = '/sprites/naruto.png' // Remplacez par le chemin du sprite de l'obstacle
    return img
  }, [])

  const gokuSprite = useMemo(() => {
    const img = new Image()
    img.src = '/sprites/goku.png' // Remplacez par le chemin du sprite de l'obstacle
    return img
  }, [])

  type Obstacle = {
    x: number
    width: number
    height: number
    isFlying: boolean
    isIchigo: boolean
    y: number
    animationFrame: number
  }

  type Sprite = {
    width: number
    height: number
    frames: number
    currentFrame: number
  }

  useEffect(() => {
    if (isGameRunning) {
      const canvas = canvasRef.current
      const ctx = canvas?.getContext('2d')

      if (!canvas || !ctx) return

      // Variables du jeu
      let isJumping = false
      let playerY = canvas.height - 60
      let playerX = 30
      let playerVelocityY = 0
      const gravity = 0.5
      const obstacles: Obstacle[] = []
      let frame = 0
      let score = 0
      const maxScore = 2000

      // Animation des sprites
      const runSpr: Sprite = {
        width: 62,
        height: 38,
        frames: 6,
        currentFrame: 0,
      }

      const gokuSpr: Sprite = {
        width: 60,
        height: 40,
        frames: 4,
        currentFrame: 0,
      }

      const ichigoSpr: Sprite = {
        width: 80,
        height: 44,
        frames: 5,
        currentFrame: 0,
      }

      const narutoSpr: Sprite = {
        width: 40,
        height: 46,
        frames: 6,
        currentFrame: 0,
      }

      // Gestion des événements : saut avec le clavier ou clic
      const handleJump = () => {
        if (!isJumping) {
          isJumping = true
          playerVelocityY = -12 // Force du saut
        }
      }

      const startGame = () => {
        window.addEventListener('keydown', e => {
          if (e.code === 'Space') handleJump()
        })
        window.addEventListener('click', handleJump)
      }

      const stopGame = () => {
        window.removeEventListener('keydown', handleJump)
        window.removeEventListener('click', handleJump)
      }

      // Fonction pour dessiner la barre de progression avec bordures
      const drawProgressBar = () => {
        const barWidth = canvas!.width // Largeur de la barre
        const barHeight = 30 // Hauteur de la barre
        const x = (canvas!.width - barWidth) / 2 // Centré horizontalement
        const y = 0 // Distance depuis le haut

        const progress = Math.min(score / maxScore, 1) // Calcul du pourcentage
        const fillWidth = progress * barWidth // Largeur remplie

        // Dessiner le fond de la barre
        ctx.fillStyle = 'rgba(0, 0, 0, 0.3)' // Fond semi-transparent
        ctx.fillRect(x, y, barWidth, barHeight)

        // Dessiner la progression
        const gradient = ctx.createLinearGradient(x, y, x + fillWidth, y)
        gradient.addColorStop(0, '#FF4500') // Départ orange
        gradient.addColorStop(1, '#FFD700') // Fin or
        ctx.fillStyle = gradient
        ctx.fillRect(x, y, fillWidth, barHeight)

        // Dessiner la bordure
        ctx.strokeStyle = '#FFFFFF' // Couleur blanche
        ctx.lineWidth = 3 // Épaisseur
        ctx.strokeRect(x, y, barWidth, barHeight)

        // Dessiner le texte
        // ctx.fillStyle = '#FFFFFF'
        // ctx.font = '16px Arial'
        // ctx.textAlign = 'center'
        // ctx.fillText(
        //   `Score: ${Math.floor(score)} / ${maxScore}`,
        //   canvas!.width / 2,
        //   y - 10
        // )
      }

      // Boucle du jeu
      const gameLoop = () => {
        frame++
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Dessiner l'arrière-plan
        ctx.drawImage(backgroundSprite, 0, 0, canvas.width, canvas.height)

        const {
          width: spriteWidth,
          height: spriteHeight,
          frames,
          currentFrame,
        } = runSpr
        const sprite = runSprite

        // Dessin du personnage
        ctx.drawImage(
          sprite,
          (currentFrame % frames) * spriteWidth,
          0,
          spriteWidth,
          spriteHeight,
          playerX,
          playerY,
          spriteWidth,
          spriteHeight
        )
        if (frame % 5 === 0) runSpr.currentFrame++ // Changer de cadre toutes les 5 frames

        // Gestion du saut
        if (isJumping) {
          playerVelocityY += gravity
          playerY += playerVelocityY

          if (playerY >= canvas.height - 50) {
            playerY = canvas.height - 50
            isJumping = false
          }
        }

        // Génération des obstacles
        const maxDifficulty = 75
        const minDifficulty = 150
        const difficulty =
          score < maxScore
            ? Math.round(minDifficulty - score / (maxScore / maxDifficulty))
            : maxDifficulty

        if (frame % difficulty === 0) {
          const isFlying = Math.random() < 0.4
          const isIchigo = !isFlying && Math.random() < 0.3
          obstacles.push({
            x: canvas.width,
            y: isFlying ? canvas.height - 150 : canvas.height - 60,
            width: isFlying
              ? gokuSpr.width
              : isIchigo
              ? ichigoSpr.width
              : narutoSpr.width,
            height: isFlying
              ? gokuSpr.height
              : isIchigo
              ? ichigoSpr.height
              : narutoSpr.height,
            isFlying,
            isIchigo,
            animationFrame: 0, // Initialisation de l'animation
          })
        }

        // Mise à jour des obstacles
        for (let i = 0; i < obstacles.length; i++) {
          const obs = obstacles[i]
          const obsSprite = obs.isFlying
            ? gokuSprite
            : obs.isIchigo
            ? ichigoSprite
            : narutoSprite
          const obsSpr = obs.isFlying
            ? gokuSpr
            : obs.isIchigo
            ? ichigoSpr
            : narutoSpr
          obs.x -= 5

          // Mettre à jour le cadre de l'animation
          if (frame % 10 === 0)
            obs.animationFrame = (obs.animationFrame + 1) % obsSpr.frames // Exemple : 4 cadres dans la sprite sheet

          const hitbox = 20
          if (
            playerX + hitbox < obs.x + obs.width &&
            playerX + spriteWidth > obs.x + hitbox &&
            playerY + spriteHeight > obs.y + hitbox &&
            playerY + hitbox < obs.y + obs.height
          ) {
            alert('Game Over! Score: ' + score)
            stopGame()
            setIsGameRunning(false)
            return
          }

          // Dessiner les obstacles avec animation
          ctx.drawImage(
            obsSprite,
            obs.animationFrame * obsSpr.width,
            0,
            obsSpr.width,
            obs.height,
            obs.x,
            obs.y,
            obs.width,
            obs.height
          )
        }

        drawProgressBar()

        // Suppression des obstacles hors écran
        obstacles.filter(obs => obs.x > -50)

        // Affichage du score
        // ctx.font = '20px Comic Sans MS'
        // ctx.fillStyle = '#000'
        // ctx.fillText(`Score: ${score}`, 10, 30)

        score++

        if (score > maxScore) {
          // alert('Bravo! Vous avez gagné!')
          stopGame()
          setIsGameRunning(false)
          setIsWin(true)
          return
        }

        if (isGameRunning) {
          animationIdRef.current = requestAnimationFrame(gameLoop)
        }
      }

      startGame()
      gameLoop()

      return () => {
        stopGame()
        if (animationIdRef.current) {
          cancelAnimationFrame(animationIdRef.current)
        }
      }
    }
  }, [
    setIsGameRunning,
    isGameRunning,
    runSprite,
    narutoSprite,
    ichigoSprite,
    backgroundSprite,
    gokuSprite,
  ])

  return (
    <>
      <div
        style={{
          position: 'absolute' as const,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(to bottom right, rgba(0, 0, 255, 0.5), rgba(128, 0, 128, 0.5))',
          zIndex: -1,
        }}
      />
      {isWin ? (
        <div
          style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}
        >
          <BackgroundCarousel />
          <Confetti width={window.innerWidth} height={window.innerHeight} />
          <BackgroundMusic />
          <MovingGifs />
          <div className="text-container">
            <div className="text-animated">Félicitations !</div>
            <div className="text-animated" style={{ marginBottom: '5vw' }}>
              Pour avoir terminé ce GOTY tu gagnes...
            </div>
            <span className="text-price">150€</span>
            <div className="text-animated" style={{ marginTop: '5vw' }}>
              De la part de tes amis chéris
            </div>
          </div>
        </div>
      ) : (
        <div style={styles.container}>
          <canvas
            ref={canvasRef}
            width={(window.innerWidth * 95) / 100}
            height={200}
            style={styles.canvas}
          />
          {!isGameRunning && (
            <button
              onClick={() => setIsGameRunning(true)}
              style={styles.startButton}
            >
              Démarrer le jeu
            </button>
          )}
          <button onClick={handleStopGame} style={styles.startButton}>
            Arrêter le jeu
          </button>
        </div>
      )}
    </>
  )
}

const styles = {
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '4vw', // Taille responsive
    fontFamily: '"Comic Sans MS", cursive, sans-serif',
    textAlign: 'center',
    zIndex: 1,
  },
  container: {
    position: 'relative' as const,
    textAlign: 'center' as const,
  },
  canvas: {
    border: '1px solid #fff',
    margin: '20px auto',
    display: 'block',
    backgroundColor: '#1e1e1e',
  },
  startButton: {
    padding: '10px 20px',
    fontSize: '18px',
    backgroundColor: '#ff6347',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
}

export default DinoGame
